<template>
  <div>
    <cpv-header></cpv-header>
    <div id="content" class="main-content">
      <router-view />
    </div>
    <cpv-footer :footer-type="'grupo'"></cpv-footer>
    <vue-snotify></vue-snotify>
    <WhatsappButton />
  </div>
</template>

<script>
import cpvHeader from "@/components/global/header.vue";
import cpvFooter from "@/components/global/newFooter";

export default {
  components: {
    cpvHeader,
    cpvFooter,
  },
};
</script>

<style scoped>
.main-content {
  font-family: "Clan-Book Book", "FF Clan Pro", sans-serif !important;
}
.viewFooter {
  display: block;
}
.hideFooter {
  display: none;
}
@media (min-width: 1200px) {
  .main-content {
    margin-top: 87px;
    /* margin-top: 95px; */
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .main-content {
    margin-top: 87px;
    /* margin-top: 95px; */
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .main-content {
    margin-top: 63px;
    /* margin-top: 70px; */
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .main-content {
    margin-top: 63px;
    /* margin-top: 70px; */
  }
}
@media (min-width: 320px) and (max-width: 576px) {
  .main-content {
    margin-top: 63px;
    /* margin-top: 70px; */
  }
}
</style>
