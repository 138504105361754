import axios from "axios";
import store from "../store";

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

const authData = JSON.parse(localStorage.getItem("auth"));
if (authData && authData.token) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${authData.token}`;
}

// axios.defaults.headers.common["Cache-Control"] = "no-cache";
// axios.defaults.withCredentials = true;

axios.interceptors.request.use(
  async (config) => {
    const privateUrls = [];
    const authRequired = privateUrls.includes(config.url);
    if (authRequired) {
      const authData = JSON.parse(localStorage.getItem("auth"));
      if (!authData || !authData.token) {
        console.log("Auth required. Request fail. Front Logout");
        await store.dispatch("auth/frontLogout");
        return Promise.reject();
        // return Promise.reject(new Error("Authorization header is missing!"));
      }
    }
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { config, response } = error;
    const status = response ? response.status : null;

    // Tratamento específico para erros de timeout
    if (
      error.code === "ECONNABORTED" ||
      error.code === "ERR_NETWORK" ||
      error.message.includes("timeout") ||
      error.message.includes("Network Error")
    ) {
      console.error("Network timeout error:", error);
      // Opcional: tentar refazer a requisição ou tomar outra ação após um tempo
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(axios(config));
        }, 5000); // Tentando refazer a requisição após 5 segundos
      });
    }

    // Tratamento de erro 401 e refresh token
    // Não ativo caso esteja na tela de login
    if (status === 401 && !response.request.responseURL.includes("site-user-login")) {
      if (config._retry) {
        return Promise.resolve("Already refreshing token");
      }

      config._retry = true;

      if (!response.request.responseURL.includes("site-user-refresh")) {
        try {
          if (!config._refreshingToken) {
            config._refreshingToken = true;
            // Tenta obter um novo token de acesso usando o refresh token
            const accessToken = await store.dispatch("auth/refreshToken");
            console.info("Atualiza o cabeçalho de autorização da requisição");
            config.headers["Authorization"] = `Bearer ${accessToken}`;

            config._refreshingToken = false;
            // Tenta refazer a requisição original com o novo token de acesso
            return axios(config);
          } else {
            console.log("Already refreshing token");
            return Promise.resolve("Already refreshing token");
          }
        } catch (refreshError) {
          // Se cair aqui é por que deu erro ao fazer o refresh token
          // Se não conseguir fazer o refresh token, desloga o usuário
          console.log("Refresh token fail. Front Logout");
          store.dispatch("auth/frontLogout");
          return Promise.resolve("Front Logout");
        }
      } else if (response.request.responseURL.includes("site-user-refresh")) {
        // Se cair aqui é por que deu 401 ao fazer o refresh token
        // Se não conseguir fazer o refresh token, desloga o usuário
        console.log("Refresh token fail. Front Logout");
        store.dispatch("auth/frontLogout");
        return Promise.resolve("Front Logout");
      }
      // } else if (status === 422) {
    } else {
      return Promise.resolve(response);
    }
  }
);
