<template>
  <b-breadcrumb class="bg-light breadcrumb__cpv">
    <!-- Primeira Parte -->
    <b-breadcrumb-item class="link__breadcrumb" :to="{ name: 'cursinho' }">
      <span class="font-weight-bolder text-uppercase">{{ mainSite }}</span>
    </b-breadcrumb-item>

    <!-- Segunda Parte  -->
    <!-- Próximas Turmas -->
    <b-breadcrumb-item
      class="link__breadcrumb"
      v-if="this.$route.meta.section_childname == 'proximasturmas' && this.$route.name !== 'proximasturmas'"
      :to="{
        name: 'proximasturmas',
      }"
    >
      <span class="font-weight-bolder text-uppercase">Próximas Turmas</span>
    </b-breadcrumb-item>
    <!-- CPV Resolve -->
    <b-breadcrumb-item
      class="link__breadcrumb"
      v-if="this.$route.meta.section_childname == 'cpv-resolve' && this.$route.name !== 'cpv-resolve'"
      :to="{
        name: 'cpv-resolve',
      }"
    >
      <span class="font-weight-bolder text-uppercase">CPV Resolve</span>
    </b-breadcrumb-item>

    <!-- Tela de matrícula -->
    <b-breadcrumb-item
      class="link__breadcrumb font-weight-bolder text-uppercase"
      v-if="this.$route.meta.section_thirdname == 'matricula'"
      :to="{ name: 'detalhe-do-curso', params: { slug: this.$route.params.slug } }"
      :active="this.$route.meta.section_thirdname == 'detalhe-do-curso'"
    >
      <strong class="text-uppercase">{{ title }}</strong>
    </b-breadcrumb-item>

    <b-breadcrumb-item v-if="this.$route.meta.section_thirdname == 'matricula'" :active="true">
      <strong class="text-uppercase">{{ pageMatriculaCourse }}</strong>
    </b-breadcrumb-item>
    <b-breadcrumb-item
      class="link__breadcrumb text-uppercase"
      active
      aria-current="page"
      v-if="this.$route.name !== 'matricula'"
      >{{ $route.meta.title || title }}
    </b-breadcrumb-item>
  </b-breadcrumb>

  <!-- <b-breadcrumb-item class="link__breadcrumb"
      v-if="this.$route.name === 'vestibular-resolvido' && this.$route.name !== 'cpv-resolve'"
      :to="{ name: 'cpv-resolve' }"
    >
      <strong class="text-uppercase">888</strong>
    </b-breadcrumb-item> -->
</template>

<script>
export default {
  name: "BreadCpv",
  props: ["title", "url", "slug"],
  data() {
    return {};
  },
  computed: {
    mainSite: function () {
      if (this.$route.meta.section_name == "cursinho") return "Cursinho";
    },

    pageMatriculaCourse: function () {
      const matriculaPathPattern = /^\/proximasturmas\/matricula\/\d+\/[\w-]+\/?$/;

      if (matriculaPathPattern.test(this.$route.path)) {
        return `Formulário de Matrícula`;
      }
    },
  },
};
</script>

<style scoped>
.active {
  font-weight: 300;
}

.breadcrumb__cpv {
  font-family: "Clan-Book Book", sans-serif;
  font-size: 15px;
  line-height: 1.5;
}

.breadcrumb__cpv .link__breadcrumb {
  text-decoration: none !important;
}
.breadcrumb__cpv .link__breadcrumb span {
  color: #888888;
}
.breadcrumb__cpv .link__breadcrumb:hover {
  text-decoration: none !important;
}
</style>
