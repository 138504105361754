<template>
  <div class="main_calendario fade-in-up">
    <b-jumbotron class="title-page mb-5 mt-5 title_page">
      <h1 class="text-center font-weight-bold">Calendário de Vestibulares</h1>
    </b-jumbotron>
    <bread-cpv class="adjustment-breadcrumb"></bread-cpv>

    <b-container fluid="lg">
      <h3 class="text-md-center title-info-calendar mb-0 mb-lg-4">
        Não perca os prazos! Fique ligado nas datas de inscrições e de provas para os princípais
        <strong>Vestibulares de São Paulo!</strong>
      </h3>
      <hr class="d-lg-none mt-4" />
      <div class="clearfix d-lg-none">
        <div class="float-left">
          <h4 class="d-lg-none mt-4 font-weight-bolder float-left">Calendário de Provas</h4>
          <b-button :id="`popover-info-${placement}`" href="#" tabindex="0" variant="transparent" class="float-right"
            ><i class="fa-light fa-circle-info fa-lg"></i
          ></b-button>
          <b-popover :target="`popover-info-${placement}`" :placement="placement" triggers="focus">
            Clique sobre a universidade desejada e fique por dentro das datas de prova.
          </b-popover>
        </div>
      </div>
      <div class="d-lg-none">
        <div v-for="(cardVestibulares, idx) in showVestibular.data" :key="idx.id" class="mt-2 mb-2">
          <b-button
            @click="toggleCard(cardVestibulares.id)"
            variant="transparent"
            href="#"
            class="btn__cardVestibulares p-3 w-100 font-weight-bolder"
            >{{ cardVestibulares.name }}</b-button
          >
          <b-collapse
            :id="'openCards' + cardVestibulares.id"
            :visible="isVisible(cardVestibulares.id)"
            class="border-top-0 border-right border-bottom border-left collapse__dataVestibulares"
          >
            <b-row v-if="cardVestibulares.name === cardVestibulares.name" class="pl-3 pr-3">
              <b-col cols="6" class="pt-3 pb-3">
                <span class="font-weight-bolder">Inscrição</span>
              </b-col>
              <b-col cols="6" class="text-center pt-3 pb-3">
                {{ cardVestibulares.init_register }}
              </b-col>
              <b-col cols="6" class="pt-3 pb-3 striped">
                <span class="font-weight-bolder">Fim da Inscrição</span>
              </b-col>
              <b-col cols="6" class="text-center pt-3 pb-3 striped">
                {{ cardVestibulares.finish_register }}
              </b-col>
              <b-col cols="6" class="pt-3 pb-3">
                <span class="font-weight-bolder">Data da prova</span>
              </b-col>
              <b-col cols="6" class="text-center pt-3 pb-3">
                <div v-for="dateVestibular in cardVestibulares.date_test" :key="dateVestibular.id">
                  {{ dateVestibular.date }}
                </div>
              </b-col>
              <b-col cols="6" class="pt-3 pb-3 striped">
                <span class="font-weight-bolder">Resultado</span>
              </b-col>
              <b-col cols="6" class="text-center pt-3 pb-3 striped">
                <div v-for="resultadoVestibular in cardVestibulares.result" :key="resultadoVestibular.id">
                  {{ resultadoVestibular.date }}
                </div>
              </b-col>
              <b-col cols="6" class="pt-3 pb-3">
                <span class="font-weight-bolder">Inscrição</span>
              </b-col>
              <b-col cols="6" class="text-center pt-3 pb-3">
                <div v-for="linkIscricao in cardVestibulares.more_info" :key="linkIscricao.id">
                  <b-link :href="linkIscricao.link_url" target="_blank" class="btn_inscCardVestibular">
                    {{ linkIscricao.banca }}
                  </b-link>
                </div>
              </b-col>
            </b-row>
          </b-collapse>
        </div>
      </div>

      <table class="table mb-5 d-none d-lg-block">
        <thead>
          <tr>
            <th
              bgcolor="#ef4857"
              v-for="(isVestibular, index) in showHeadertTable.data"
              :key="index"
              style="color: #ffffff; text-align: center; font-size: 14px; font-weight: 600"
            >
              {{ isVestibular.name }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(isVestibular, index) in showVestibular.data"
            :key="`${index}_${isVestibular.name}`"
            :class="{ secondline: index % 2 === 1 }"
          >
            <td style="width: 295px">
              <span>{{ isVestibular.name }}</span>
            </td>
            <td class="text-center">
              <span v-if="isVestibular.init_register != ''">{{ isVestibular.init_register }}</span>
              <span v-else>A definir</span>
            </td>
            <td class="text-center">
              <span v-if="isVestibular.finish_register != ''">{{ isVestibular.finish_register }}</span>
              <span v-else>A definir</span>
            </td>
            <td class="text-center" style="width: 285px">
              <b-row>
                <b-col cols="12" v-for="initDate in isVestibular.date_test" :key="initDate.date">
                  <div v-if="initDate.date && initDate.date.length > 2 && initDate.date != ''">{{ initDate.date }}</div>
                  <div v-else>A definir</div>
                </b-col>
              </b-row>
            </td>
            <td class="text-center">
              <b-row>
                <b-col cols="12" v-for="showResult in isVestibular.result" :key="showResult.date">
                  <div v-if="showResult.date && showResult.date.length > 2 && showResult.date != ''">
                    {{ showResult.date }}
                  </div>
                  <div v-else>A definir</div>
                </b-col>
              </b-row>
            </td>
            <td class="text-center link-info" v-for="vestibularInsc in isVestibular.more_info" :key="vestibularInsc">
              <b-link :href="vestibularInsc.link_url" class="link" target="_blank">
                <span>
                  {{ vestibularInsc.banca }}
                </span>
              </b-link>
            </td>
          </tr>
        </tbody>
      </table>
    </b-container>
  </div>
</template>

<script>
import BreadCpv from "@/components/global/bread-cpv.vue";
export default {
  name: "CalendarioVestibular",
  metaInfo: { title: "Calendário de Vestibulares" },
  components: { BreadCpv },
  data() {
    return {
      placement: "top",
      showHeadertTable: {
        data: [
          { name: "Instituição" },
          { name: "Inscrições" },
          { name: "Fim das Inscrições" },
          { name: "Prova" },
          { name: "Resultado" },
          { name: "Informações" },
        ],
      },
      showVestibular: {
        data: [
          {
            id: 0,
            name: "Anhembi Morumbi (Medicina)",
            init_register: "",
            finish_register: "",
            date_test: [{ id: 0, date: "" }],
            result: [{ id: 0, date: "" }],
            more_info: [{ id: 0, banca: "Vunesp", link_url: "https://www.vunesp.com.br/ANIM2401" }],
          },
          {
            id: 1,
            name: "Cásper Líbero",
            init_register: "",
            finish_register: "",
            date_test: [{ id: 1, date: "" }],
            result: [{ id: 1, date: "" }],
            more_info: [
              {
                id: 0,
                banca: "Cásper Líbero",
                link_url:
                  "https://casperlibero.edu.br/vestibular/?utm_source=site&utm_medium=botao_vestibular_home_site&utm_campaign=vestibular_2025",
              },
            ],
          },
          {
            id: 2,
            name: "Einstein (Medicina)",
            init_register: "",
            finish_register: "",
            date_test: [{ id: 1, date: "" }],
            result: [{ id: 1, date: "" }],
            more_info: [{ id: 0, banca: "Vunesp", link_url: "https://www.vunesp.com.br/FEAE2405" }],
          },
          {
            id: 3,
            name: "Einstein",
            init_register: "",
            finish_register: "",
            date_test: [{ id: 1, date: "" }],
            result: [{ id: 1, date: "" }],
            more_info: [{ id: 0, banca: "Vunesp", link_url: "https://www.vunesp.com.br/FEAE2405" }],
          },
          {
            id: 4,
            name: "Enem",
            init_register: "",
            finish_register: "",
            date_test: [{ id: 1, date: "" }],
            result: [{ id: 1, date: "" }],
            more_info: [
              {
                id: 0,
                banca: "INEP ENEM",
                link_url: "https://www.gov.br/inep/pt-br/assuntos/noticias/enem/inscricoes-do-enem-2024-abertas",
              },
            ],
          },
          {
            id: 5,
            name: "ESPM SP e RJ",
            init_register: "25/02/2025",
            finish_register: "10/06/2025",
            date_test: [
              { id: 1, date: "Entrevista On-line: 31/03 a 10/06/2025" },
              { id: 1, date: "Prova Presencial: 15/06/2025" },
            ],
            result: [
              { id: 1, date: "1ª Chamada: 23/06/2025" },
              { id: 2, date: "2ª Chamada: 30/06/2025" },
            ],
            more_info: [
              {
                id: 0,
                banca: "ESPM",
                link_url:
                  "https://www.espm.br/cursos-de-graduacao/processos-seletivos/vestibular/?utm_source=google&utm_medium=cpc&utm_campaign=sp-vestibular-2025_1-search-institucional-branding-institucional&utm_content=sp-vestibular-2025_1-institucional-branding-institucional-search-ad1_inscreva_se_agora&utm_campaign=SP-VESTIBULAR-2025_1-SEARCH-INSTITUCIONAL-BRANDING&utm_medium=ppc&utm_source=adwords&utm_term=vestibular%20espm&hsa_mt=b&hsa_net=adwords&hsa_ver=3&hsa_kw=vestibular%20espm&hsa_acc=2499614682&hsa_grp=178145731880&hsa_tgt=kwd-802050701208&hsa_src=g&hsa_ad=735152185899&hsa_cam=22292011610&gad_source=1&gclid=CjwKCAjwvr--BhB5EiwAd5YbXlutoGRQ02n60wI-D30YUlxXWO5oCRugGpKYXCAsq2W0CqSR7rNSMxoC2tkQAvD_BwE",
              },
            ],
          },
          {
            id: 6,
            name: "Faculdade Belavista",
            init_register: "",
            finish_register: "",
            date_test: [{ id: 1, date: "" }],
            result: [{ id: 1, date: "" }],
            more_info: [{ id: 0, banca: "Vunesp", link_url: "https://www.vunesp.com.br/fbvi2401" }],
          },
          {
            id: 7,
            name: "Faculdade de Medicina de Jundiaí",
            init_register: "",
            finish_register: "",
            date_test: [{ id: 1, date: "" }],
            result: [{ id: 1, date: "" }],
            more_info: [{ id: 0, banca: "Vunesp", link_url: "https://www.vunesp.com.br/FMJU2401" }],
          },
          {
            id: 8,
            name: "Faculdade Santa Marcelina - FASM",
            init_register: "",
            finish_register: "",
            date_test: [{ id: 1, date: "" }],
            result: [{ id: 1, date: "" }],
            more_info: [{ id: 0, banca: "Vunesp", link_url: "https://www.vunesp.com.br/FSTM2402" }],
          },
          {
            id: 9,
            name: "Fema (Medicina)",
            init_register: "",
            finish_register: "",
            date_test: [{ id: 1, date: "" }],
            result: [{ id: 1, date: "" }],
            more_info: [{ id: 0, banca: "Vunesp", link_url: "https://www.vunesp.com.br/FEAS2402" }],
          },
          {
            id: 10,
            name: "FGV ADM de Empresas (SP)",
            init_register: "",
            finish_register: "",
            date_test: [{ id: 1, date: "" }],
            result: [{ id: 1, date: "" }],
            more_info: [
              {
                id: 0,
                banca: "FGV",
                link_url: "https://vestibular.fgv.br/graduacao/formas-de-ingresso/vestibular-fgv",
              },
            ],
          },
          {
            id: 11,
            name: "FGV ADM Pública (SP)",
            init_register: "",
            finish_register: "",
            date_test: [{ id: 1, date: "" }],
            result: [{ id: 1, date: "" }],
            more_info: [
              {
                id: 0,
                banca: "FGV",
                link_url: "https://vestibular.fgv.br/graduacao/formas-de-ingresso/vestibular-fgv",
              },
            ],
          },
          {
            id: 12,
            name: "FGV Direito (SP)",
            init_register: "",
            finish_register: "",
            date_test: [{ id: 1, date: "" }],
            result: [{ id: 1, date: "" }],
            more_info: [
              {
                id: 0,
                banca: "FGV",
                link_url: "https://vestibular.fgv.br/graduacao/formas-de-ingresso/vestibular-fgv",
              },
            ],
          },
          {
            id: 13,
            name: "FGV Economia (SP)",
            init_register: "",
            finish_register: "",
            date_test: [{ id: 1, date: "" }],
            result: [{ id: 1, date: "" }],
            more_info: [
              {
                id: 0,
                banca: "FGV",
                link_url: "https://vestibular.fgv.br/graduacao/formas-de-ingresso/vestibular-fgv",
              },
            ],
          },
          {
            id: 14,
            name: "FGV Relações Internacionais (SP)",
            init_register: "",
            finish_register: "",
            date_test: [{ id: 1, date: "" }],
            result: [{ id: 1, date: "" }],
            more_info: [
              {
                id: 0,
                banca: "FGV",
                link_url: "https://vestibular.fgv.br/graduacao/formas-de-ingresso/vestibular-fgv",
              },
            ],
          },
          {
            id: 15,
            name: "FMABC",
            init_register: "",
            finish_register: "",
            date_test: [{ id: 1, date: "" }],
            result: [{ id: 1, date: "" }],
            more_info: [{ id: 0, banca: "Vunesp", link_url: "https://www.vunesp.com.br/FABC2402" }],
          },
          {
            id: 16,
            name: "Fuvest - USP",
            init_register: "18/08/2025",
            finish_register: "07/10/2025",
            date_test: [
              { id: 1, date: "1ª fase: 23/11/2025" },
              { id: 1, date: "2ª fase: 14/12 e 15/12/2025" },
            ],
            result: [{ id: 1, date: "23/01/2026" }],
            more_info: [{ id: 0, banca: "Fuvest", link_url: "https://www.fuvest.br/vestibular-da-usp" }],
          },
          {
            id: 17,
            name: "Humanitas (Medicina)",
            init_register: "14/03/2025",
            finish_register: "23/04/2025",
            date_test: [{ id: 1, date: "16/05/2025" }],
            result: [
              { id: 1, date: "1ª Chamada: 12/06/2025" },
              { id: 2, date: "2ª Chamada: 18/06/2025" },
            ],
            more_info: [{ id: 0, banca: "Vunesp", link_url: "https://www.vunesp.com.br/FCMH2502" }],
          },
          {
            id: 18,
            name: "Ibmec",
            init_register: "",
            finish_register: "",
            date_test: [{ id: 1, date: "" }],
            result: [{ id: 1, date: "" }],
            more_info: [{ id: 0, banca: "IBMEC", link_url: "https://www.ibmec.br/vestibular" }],
          },
          {
            id: 19,
            name: "Insper (São Paulo)",
            init_register: "27/02/2025",
            finish_register: "27/05/2025",
            date_test: [{ id: 1, date: "20/06 a 23/06/2025" }],
            result: [
              { id: 1, date: "1ª Chamada: 11/07/2025" },
              { id: 2, date: "2ª Chamada: 17/07/2025" },
            ],
            more_info: [{ id: 0, banca: "Insper", link_url: "https://www.insper.edu.br/vestibular/edital/" }],
          },
          {
            id: 20,

            name: "Insper (Fortaleza e Goiânia)",
            init_register: "27/02/2025",
            finish_register: "27/05/2025",
            date_test: [{ id: 1, date: "07/06 e 08/06/2025" }],
            result: [
              { id: 1, date: "1ª Chamada: 11/07/2025" },
              { id: 2, date: "2ª Chamada: 17/07/2025" },
            ],
            more_info: [{ id: 0, banca: "Insper", link_url: "https://www.insper.edu.br/vestibular/edital/" }],
          },
          {
            id: 21,
            name: "Insper (Recife e Brasília)",
            init_register: "27/02/2025",
            finish_register: "27/05/2025",
            date_test: [{ id: 1, date: "10/06/2025" }],
            result: [
              { id: 1, date: "1ª Chamada: 11/07/2025" },
              { id: 2, date: "2ª Chamada: 17/07/2025" },
            ],
            more_info: [{ id: 0, banca: "Insper", link_url: "https://www.insper.edu.br/vestibular/edital/" }],
          },
          {
            id: 22,
            name: "Insper (Salvador e Rio de Janeiro)",
            init_register: "27/02/2025",
            finish_register: "27/05/2025",
            date_test: [{ id: 1, date: "12/06 e 13/06/2025" }],
            result: [
              { id: 1, date: "1ª Chamada: 11/07/2025" },
              { id: 2, date: "2ª Chamada: 17/07/2025" },
            ],
            more_info: [{ id: 0, banca: "Insper", link_url: "https://www.insper.edu.br/vestibular/edital/" }],
          },
          {
            id: 23,
            name: "Insper (Belo Horizonte e Florianópolis)",
            init_register: "27/02/2025",
            finish_register: "27/05/2025",
            date_test: [{ id: 1, date: "15/06/2025" }],
            result: [
              { id: 1, date: "1ª Chamada: 11/07/2025" },
              { id: 2, date: "2ª Chamada: 17/07/2025" },
            ],
            more_info: [{ id: 0, banca: "Insper", link_url: "https://www.insper.edu.br/vestibular/edital/" }],
          },
          {
            id: 25,
            name: "Insper (Ribeirão Preto)",
            init_register: "27/02/2025",
            finish_register: "27/05/2025",
            date_test: [{ id: 1, date: "17/06/2025" }],
            result: [
              { id: 1, date: "1ª Chamada: 11/07/2025" },
              { id: 2, date: "2ª Chamada: 17/07/2025" },
            ],
            more_info: [{ id: 0, banca: "Insper", link_url: "https://www.insper.edu.br/vestibular/edital/" }],
          },
          {
            id: 27,
            name: "Inteli",
            init_register: "",
            finish_register: "",
            date_test: [{ id: 1, date: "" }],
            result: [{ id: 1, date: "" }],
            more_info: [{ id: 0, banca: "Inteli", link_url: "https://www.inteli.edu.br/processo-seletivo/" }],
          },
          {
            id: 28,
            name: "Link School of Business",
            init_register: "",
            finish_register: "",
            date_test: [{ id: 1, date: "" }],
            result: [{ id: 1, date: "" }],
            more_info: [{ id: 0, banca: "LSB", link_url: "https://lsb.edu.br/processo-seletivo/" }],
          },
          {
            id: 29,
            name: "Mackenzie - Alphaville (Medicina)",
            init_register: "",
            finish_register: "",
            date_test: [{ id: 1, date: "" }],
            result: [{ id: 1, date: "" }],
            more_info: [
              {
                id: 0,
                banca: "Mackenzie",
                link_url: "https://www.mackenzie.br/processos-seletivos/vestibular-graduacao/medicina",
              },
            ],
          },
          {
            id: 30,
            name: "Mackenzie - Alphaville",
            init_register: "",
            finish_register: "",
            date_test: [{ id: 1, date: "" }],
            result: [{ id: 1, date: "" }],
            more_info: [
              {
                id: 0,
                banca: "Mackenzie",
                link_url:
                  "https://www.mackenzie.br/processos-seletivos/vestibular-graduacao/universidade-campus-alphaville",
              },
            ],
          },
          {
            id: 31,
            name: "Mackenzie - Campinas",
            init_register: "",
            finish_register: "",
            date_test: [{ id: 1, date: "" }],
            result: [{ id: 1, date: "" }],
            more_info: [
              {
                id: 0,
                banca: "Mackenzie",
                link_url: "https://www.mackenzie.br/processos-seletivos/vestibular-graduacao/campinas",
              },
            ],
          },
          {
            id: 32,
            name: "Mackenzie - Higienópolis",
            init_register: "",
            finish_register: "",
            date_test: [{ id: 1, date: "" }],
            result: [{ id: 1, date: "" }],
            more_info: [
              {
                id: 0,
                banca: "Mackenzie",
                link_url: "https://www.mackenzie.br/processos-seletivos/vestibular-graduacao/sao-paulo-higienopolis",
              },
            ],
          },
          {
            id: 33,
            name: "PUC-SP",
            init_register: "",
            finish_register: "",
            date_test: [{ id: 1, date: "" }],
            result: [{ id: 1, date: "" }],
            more_info: [{ id: 0, banca: "PUC", link_url: "https://www.pucsp.br/graduacao" }],
          },
          {
            id: 34,
            name: "São Camilo (Medicina)",
            init_register: "07/03/2025",
            finish_register: "15/05/2025",
            date_test: [{ id: 1, date: "08/06/2025" }],
            result: [
              { id: 1, date: "1ª Chamada: 08/07/2025" },
              { id: 2, date: "2ª Chamada: 16/07/2025" },
            ],
            more_info: [
              {
                id: 0,
                banca: "Vunesp",
                link_url: "https://www.vunesp.com.br/CUSC2402",
              },
            ],
          },
          {
            id: 35,
            name: "Unesp",
            init_register: "",
            finish_register: "",
            date_test: [{ id: 1, date: "" }],
            result: [{ id: 1, date: "" }],
            more_info: [{ id: 0, banca: "Vunesp", link_url: "https://www.vunesp.com.br/VNSP2404" }],
          },
          {
            id: 36,
            name: "Unicamp",
            init_register: "",
            finish_register: "",
            date_test: [{ id: 1, date: "" }],
            result: [{ id: 1, date: "" }],
            more_info: [
              { id: 0, banca: "Comvest", link_url: "https://www.comvest.unicamp.br/ingresso-2024/vestibular-2024-2/" },
            ],
          },
          {
            id: 37,
            name: "Unifev (Medicina)",
            init_register: "",
            finish_register: "",
            date_test: [{ id: 1, date: "" }],
            result: [{ id: 1, date: "" }],
            more_info: [{ id: 0, banca: "Vunesp", link_url: "https://www.vunesp.com.br/?b=UNIFEV" }],
          },
          {
            id: 38,
            name: "Unisa (Medicina)",
            init_register: "10/02/2025",
            finish_register: "25/05/2025",
            date_test: [{ id: 1, date: "15/06/2025" }],
            result: [{ id: 1, date: "02/07/2025" }],
            more_info: [
              {
                id: 0,
                banca: "Vunesp",
                link_url: "https://www.vunesp.com.br/UNIS2501",
              },
            ],
          },
          {
            id: 39,
            name: "USCS (Medicina)",
            init_register: "",
            finish_register: "",
            date_test: [{ id: 1, date: "" }],
            result: [{ id: 1, date: "" }],
            more_info: [
              {
                id: 0,
                banca: "Vunesp",
                link_url: "https://www.vunesp.com.br/USCS2402",
              },
            ],
          },
          {
            id: 40,
            name: "Santa Casa (Medicina)",
            init_register: "",
            finish_register: "",
            date_test: [{ id: 1, date: "" }],
            result: [{ id: 1, date: "" }],
            more_info: [{ id: 0, banca: "Vunesp", link_url: "https://www.vunesp.com.br/FAVC2401" }],
          },
          {
            id: 41,
            name: "Santa Casa",
            init_register: "",
            finish_register: "",
            date_test: [{ id: 1, date: "" }],
            result: [{ id: 1, date: "" }],
            more_info: [{ id: 0, banca: "Vunesp", link_url: "https://www.vunesp.com.br/FAVC2401" }],
          },
        ],
      },

      activeCard: null,
    };
  },
  created() {
    if (this.showVestibular.data.length > 0) {
      this.activeCard = this.showVestibular.data[0].id;
    }
  },
  beforeDestroy: function () {
    //
  },
  methods: {
    toggleCard(id) {
      this.activeCard = this.activeCard === id ? null : id;
    },
    isVisible(id) {
      return this.activeCard === id;
    },
  },
};
</script>

<style lang="scss" scoped>
.main_calendario {
  margin: 0px 0px 15px;
  padding: 0;
  font-size: 14px;
}

.fade-in-up {
  -webkit-animation: fadeIn 0.4s ease-in-out;
  -moz-animation: fadeIn 0.4s ease-in-out;
  -o-animation: fadeIn 0.4s ease-in-out;
  animation: fadeIn 0.4s ease-in-out;
}

.title_page {
  margin: -8px 0px 0px !important;
  background-repeat: repeat;
  background-position: center;
  background-color: rgb(239, 72, 87);
  background-image: url("https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/cpv-fundo-4.png");
  background-size: 30%;
  // background-attachment: fixed;
  object-fit: cover;
  border-radius: 0;
}

.title_page h1 {
  font-size: 51px;
  font-weight: 700;
  color: #ffffff;
  text-transform: uppercase;
  font-family: "FF Clan Pro", sans-serif;
  font-weight: bold;
}

.secondline {
  background-color: #f6f6f6;
}

.link-info .link {
  text-decoration: none;
  font-weight: 600;
  color: rgb(58, 28, 83);
}

.link-info .link:hover {
  color: rgb(239, 72, 87);
  transition: all 0.3s ease;
}

.devices-sm {
  display: none;
}

.devices-lg {
  font-family: "Clan-Book Book", sans-serif;
  color: rgb(58, 28, 83);
}

.title-info-calendar {
  font-size: 19px;
  margin-bottom: 40px;
  font-weight: 600;
  padding: 0px 150px;
  font-family: "Clan-Book Book", sans-serif;

  span {
    color: rgb(58, 28, 83);
  }

  .adjustment-breadcrumb .breadcrumb-item + .breadcrumb-item::before {
    float: left !important;
    padding-right: 0.5rem !important;
    padding-top: 0.02rem !important;
    font-size: 13px !important;
    color: #aaaaaa !important;
    content: ">" !important;
  }
}

.btn__cardVestibulares {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e0e0e0;
  background-color: #ef4758;
  color: #ffffff;
}

.collapse__dataVestibulares {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid #e0e0e0;
}

.striped {
  background-color: #f0f0f0;
}

.btn_inscCardVestibular {
  color: #ffffff;
  font-weight: 600;
  background-color: #ef4857;
  min-width: auto;
  min-height: 35px;
  padding: 8px 16px;
  border-radius: 5px;
  text-decoration: none;

  &:hover {
    color: #ffffff;
  }
}

@media (min-width: 577px) and (max-width: 767px) {
  .devices-lg {
    display: none;
  }

  .devices-sm {
    display: block;
    font-family: "Clan Book", sans-serif;
  }

  .header-title {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 56px;
    width: 100%;

    background-color: rgb(239, 72, 87);
    border: 1px solid rgb(239, 72, 87);
    border-radius: 5px;
  }

  .header-title span {
    font-size: 16.5px;
    font-weight: 700;

    color: #ffffff;
    letter-spacing: 0.008em;
  }

  .card_prova {
    border: none;
  }

  .btn__cardVestibulares {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #e0e0e0;
    background-color: #ef4758;
    color: #ffffff;

    &:hover {
      color: #ffffff;
    }
  }

  .btn_calendar_toggle_university {
    background-color: rgb(239, 72, 87);
    border: 2px solid rgb(239, 72, 87);
  }

  .btn_calendar_toggle_university span {
    color: #ffffff;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 15px;
  }

  .btn_more_info {
    display: block;
    margin: 0px auto 0px;
    width: 65%;
  }

  .btn_more_info .btn_info {
    background-color: transparent;
    border: 2px solid rgb(239, 72, 87);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 38px;
    border-radius: 5px;
    text-decoration: none;
  }

  .btn_more_info .btn_info span {
    color: rgb(239, 72, 87);
    font-weight: 700;
    letter-spacing: 0.008em;
    text-decoration: none;
  }

  .card_calendar_date {
    border-top: none;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-color: #e5e5e5;
  }

  .card_calendar_date .header_title span {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.005em;
  }

  .card_calendar_date .header_title span {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.005em;
  }

  .card_calendar_date .info_dates span {
    font-size: 14px;
    font-weight: 500;
  }

  .bg_color_line_row_date {
    background-color: #f6f6f6;
    padding: 20px 0px;
  }

  .info_navigation span {
    font-size: 15px;
  }

  .title-info-calendar {
    font-size: 19px;
    margin-bottom: 40px;
    font-weight: 600;
    padding: 0px 0px;
    font-family: "Clan-Book Book", sans-serif;

    span {
      color: rgb(58, 28, 83);
    }
  }
}

@media (min-width: 320px) and (max-width: 576px) {
  .title_page {
    font-family: "FF Clan Pro", sans-serif;
    margin: 0px 0px -10px;
    padding: 55px 0px 50px;
    background-repeat: repeat;
    background-position: center;
    background-color: rgb(239, 72, 87);
    background-image: url("https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/cpv-fundo-4.png");
    border-radius: 0;
  }

  .title_page h1 {
    font-size: 41px;
    font-weight: 700;
    color: #ffffff;
    text-transform: uppercase;
  }

  .title-info-calendar {
    font-size: 19px;
    margin-bottom: 40px;
    font-weight: 600;
    padding: 0px 0px;
    font-family: "Clan-Book Book", sans-serif;

    span {
      color: rgb(58, 28, 83);
    }
  }

  .devices-lg {
    display: none;
  }

  .devices-sm {
    display: block;
    font-family: "Clan-Book Book", sans-serif;
  }

  .header-title {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 56px;
    width: 100%;

    background-color: rgb(239, 72, 87);
    border: 1px solid rgb(239, 72, 87);
    border-radius: 5px;
  }

  .header-title span {
    font-size: 16.5px;
    font-weight: 700;
    text-transform: uppercase;

    color: #ffffff;
    letter-spacing: 0.008em;
  }

  .card_prova {
    border: none;
  }

  .btn__cardVestibulares {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #e0e0e0;
    background-color: #ef4758;
    color: #ffffff;

    &:hover {
      color: #ffffff;
    }
  }

  .btn_calendar_toggle_university {
    background-color: rgb(239, 72, 87);
    border: 2px solid rgb(239, 72, 87);
  }

  .btn_calendar_toggle_university span {
    color: rgb(255, 255, 255);
    font-weight: 700;
    text-transform: uppercase;
    font-size: 15px;
  }

  .btn_more_info {
    display: block;
    margin: 0px auto 0px;
    width: 65%;
  }

  .btn_more_info .btn_info {
    background-color: transparent;
    border: 2px solid rgb(239, 72, 87);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 38px;
    border-radius: 5px;
    text-decoration: none;
  }

  .btn_more_info .btn_info span {
    color: rgb(239, 72, 87);
    font-weight: 700;
    letter-spacing: 0.008em;
    text-decoration: none;
  }

  .card_calendar_date {
    border-top: none;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-color: #e5e5e5;
  }

  .card_calendar_date .header_title span {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.005em;
  }

  .card_calendar_date .header_title span {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.005em;
  }

  .card_calendar_date .info_dates span {
    font-size: 14px;
    font-weight: 500;
  }

  .bg_color_line_row_date {
    background-color: #f6f6f6;
    padding: 20px 0px;
  }

  .info_navigation span {
    font-size: 15px;
  }
}
</style>
