import "../../functions";

const state = {
  logged: null,
  initializing: false,
  user: null,
  userType: null,
  userpermissions: null,
  refreshingToken: false,
  loggingIn: false,
};

const mutations = {
  setLogged(state, value) {
    state.logged = value;
  },
  setInitializing(state, value) {
    state.initializing = value;
  },
  setRefreshingToken(state, value) {
    state.refreshingToken = value;
  },
  setLoggingIn(state, value) {
    state.loggingIn = value;
  },
  setUser(state, user) {
    state.user = user;
  },
  setUserType(state, usertype) {
    state.userType = usertype;
  },
  setUserPermissions(state, userpermissions) {
    state.userpermissions = userpermissions;
  },
  setLogout(state) {
    // Excluo o cookie sitecpv_destino
    $cookies.remove("sitecpv_destino");
    state.user = null;
    state.userType = null;
    state.userpermissions = null;
    state.logged = false;
  },
};

const actions = {
  login({ commit, dispatch }, credentials) {
    return new Promise((resolve, reject) => {
      commit("setLoggingIn", true);
      try {
        axios
          .post("auth/site-user-login", {
            cpf: credentials.cpf,
            password: credentials.password,
          })
          .then(async (response) => {
            if (response.status == 200) {
              commit("setLogged", true);
              commit("setUserType", "siteuser");
              axios.defaults.headers.common["Authorization"] = `Bearer ${response.data.access_token}`;
              // Persistir o estado de autenticação no localStorage
              localStorage.setItem(
                "auth",
                JSON.stringify({
                  token: response.data.access_token,
                  refreshToken: response.data.refresh_token,
                  tokenExpiresIn: response.data.expires_in,
                  userType: "siteuser",
                })
              );

              await dispatch("fetchUser");
              commit("setLoggingIn", false);
              resolve(response);
            } else {
              // console.log(response.data.error);
              commit("setLoggingIn", false);
              resolve(response);
            }
          });
      } catch (error) {
        console.error("Login failed:", error);
        // throw error; // Re-throw the error to handle it in the component
        commit("setLoggingIn", false);
        reject(error);
      }
    });
  },
  register({ commit, dispatch }, registerData) {
    return new Promise((resolve, reject) => {
      try {
        axios
          .post(`sitecpv/register`, {
            cpf: registerData.cpf,
            estrangeiro: registerData.estrangeiro,
            first_name: registerData.first_name,
            last_name: registerData.last_name,
            name: registerData.name,
            email: registerData.email,
            celular: registerData.celular,
            password: registerData.password,
          })
          .then((response) => {
            if (response.status == 200) {
              resolve(response);
            } else {
              console.log(response.error);
              reject(response);
            }
          });
      } catch (error) {
        console.error("Login failed:", error);
        // throw error; // Re-throw the error to handle it in the component
        reject(error);
      }
    });
  },
  async fetchUser({ commit }) {
    return new Promise((resolve, reject) => {
      try {
        axios.get("sitecpv/users/site-auth-user").then((response) => {
          if (response.status === 200) {
            commit("setUser", response.data.user);
            localStorage.setItem("user", JSON.stringify(response.data.user));
            resolve();
          } else {
            console.error("Falha ao bucar o usuário");
            reject("Falha ao bucar o usuário");
          }
        });
      } catch (error) {
        console.error("Failed to fetch user:", error);
        reject("Failed to fetch user");
        // Se habilitar a linha abaixo entregará erro no front do site. Não queremos isso.
        // throw error; // Re-throw the error to handle it in the component
      }
    });
  },
  async initialize({ commit, dispatch }) {
    if (!state.initializing) {
      console.info("Inicializando aplicação");
      commit("setInitializing", true);

      const authData = JSON.parse(localStorage.getItem("auth"));
      const authUser = JSON.parse(localStorage.getItem("user"));
      if (authData && authUser) {
        commit("setUserType", authData.userType);
        if (authData.userType == "siteuser") {
          commit("setLogged", true);
          commit("setUser", authUser);
          await dispatch("fetchUser");
        } else {
          commit("setLogged", false);
        }
      }
      commit("setInitializing", false);
      console.info("Aplicação inicializada");
    }
  },
  async refreshToken({ commit }) {
    try {
      const authData = JSON.parse(localStorage.getItem("auth"));
      if (authData && authData.token) {
        commit("setRefreshingToken", true);
        console.info("Refreshing token");

        const response = await axios.post("auth/site-user-refresh", {
          refresh_token: authData.refreshToken,
        });

        const access_token = response.data.access_token;
        const refresh_token = response.data.refresh_token;
        const expires_in = response.data.expires_in;

        axios.defaults.headers.common["Authorization"] = `Bearer ${access_token}`;

        // Persistir o estado de autenticação no localStorage
        localStorage.setItem(
          "auth",
          JSON.stringify({
            token: access_token,
            refreshToken: refresh_token,
            tokenExpiresIn: expires_in,
            userType: "siteuser",
          })
        );

        commit("setRefreshingToken", false);
        return access_token;
      } else {
        commit("setLogout");
        delete axios.defaults.headers.common["Authorization"];
        localStorage.clear();

        commit("setRefreshingToken", false);
        return "Refresh token indisponível";
      }
    } catch (error) {
      commit("setLogout");
      delete axios.defaults.headers.common["Authorization"];
      localStorage.clear();

      commit("setRefreshingToken", false);
      return "Failed to refresh token:", error;
    }
  },
  async logout({ commit }) {
    try {
      await axios.get("sitecpv/users/logout");
      commit("setLogout");
      delete axios.defaults.headers.common["Authorization"];
      localStorage.clear();
      window.location.href = "/";
    } catch (error) {
      console.log(error);
    }
  },
  async frontLogout({ commit }) {
    // const environment = Vue.prototype.$detectEnvironment();
    // if (!environment.isMobile) {
    //   await NotifyService.destroy();
    // }
    commit("setLogout");
    delete axios.defaults.headers.common["Authorization"];
    localStorage.clear();
    window.location.href = "/login";
    // router.push({ name: "cursinho" });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
