<template>
  <div class="main-bolsa mb-4">
    <b-jumbotron class="title_page">
      <h1 class="text-center font-weight-bold">Exame de Bolsa</h1>
    </b-jumbotron>
    <bread-cpv class="adjustment-breadcrumb"></bread-cpv>

    <b-container fluid="xl" class="content-bolsa-font">
      <!-- <p>
        No momento não temos uma data definida para a realização do próximo Exame de Bolsa. Entretanto, para garantir
        que você seja avisado assim que uma data for estabelecida, convidamos você a preencher o Formulário de
        Interesse.
      </p>
      <p>
        Ao registrar seu interesse, você estará entre os primeiros a receber informações atualizadas sobre o exame, além
        de detalhes importantes para sua participação.
      </p> -->
      <div class="header">
        <h4 class="text-uppercase font-weight-bolder">Exame de bolsa dia <span class="emphasis">03/07/2025,</span> quinta-feira, das <span class="emphasis">9h às 13h.</span> <br> Prova <span class="emphasis">100% on-line</span></h4>
      </div>
      <div class="content-bolsa">
        <!-- <h6 class="font-weight-bold">Você pode escolher o horário da sua prova:</h6>
        <ul class="ml-4">
          <li>15h00 às 19h00</li>
        </ul> -->
        <!-- <h5 class="font-weight-bold text-uppercase">Prova <span class="emphasis">100% On-line.</span></h5> -->
        <p class="mt-3">
          O <strong>CPV</strong> oferece regularmente um <strong>Exame de Bolsas</strong> com desconto
          <strong>de até 100%</strong>, para estudantes que desejam se preparar para o vestibular. Está é a sua chance
          de garantir descontos exclusivos nas mensalidades dos nossos cursos!
        </p>
        <p>
          Com base no seu desempenho na prova, que é cuidadosamente elaborada com o conteúdo do Ensino Médio, você pode
          conquistar descontos significativos. Esses descontos são aplicados diretamente no valor contratual, tornando a
          sua preparação mais acessível e eficiente.
        </p>
        <p>
          <strong>O aluno que se matricular e for aprovado em algum vestibular pode solicitar o cancelamento da matrícula, com 100% de devolução do valor pago, desde que o pedido seja feito antes do início das aulas.</strong>
        </p>
      </div>
      <div class="d-flex align-items-cente justify-content-center">
        <!-- <a class="btn__link" href="https://dicas.cpv.com.br/interesse-exame-de-bolsa">
          <span class="d-block">Turmas</span>
          <span>1º Semestre</span>
        </a> -->
        <!-- <a class="btn__link btn-segundo" href="https://dicas.cpv.com.br/exame-de-bolsa-turmas-2025-2">
          <span class="d-block">Turmas</span>
          <span>2º Semestre</span>
        </a> -->
        <a class="btn btn-inscricao mt-3" href="https://dicas.cpv.com.br/exame-de-bolsa-turmas-2025-2" alt="Incrição Exame de Bolsa" target="_blank">Clique aqui e inscreva-se</a>
      </div>
    </b-container>
  </div>
</template>

<script>
import BreadCpv from "@/components/global/bread-cpv.vue";
export default {
  name: "ExameDeBolsa",
  components: { BreadCpv },
  data() {
    return {
      //
    };
  },
  created: function () {
    //
  },
  beforeDestroy: function () {
    //
  },
  methods: {
    //
  },
};
</script>

<style lang="scss" scoped>
.fade-in-up {
  -webkit-animation: fadeIn 0.4s ease-in-out;
  -moz-animation: fadeIn 0.4s ease-in-out;
  -o-animation: fadeIn 0.4s ease-in-out;
  animation: fadeIn 0.4s ease-in-out;
}

.btn__link,
.btn-segundo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  min-width: 135px;
  height: 127.5px;
  margin: 0 10px;
  padding: 30px 50px;

  span {
    color: #fff;
    font-size: 20px;
    font-weight: 700;
  }

  i {
    color: #fff;
    font-size: 45px;
    line-height: 60px;
  }

  text-transform: uppercase;

  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  text-align: center;
  outline: center;

  text-decoration: none;
  border-radius: 8px;
}

.btn-inscricao {
  padding: 8px 16px;

  color: rgba($color: #FFFFFF, $alpha: 1.0);
  
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 1.25px;
  text-transform: uppercase;

  background-image: none;
  background: linear-gradient(
    90deg,
    rgb(239, 50, 66) 0%,
    rgb(239, 50, 66) 50%,
    rgb(239, 72, 87) 50%,
    rgb(239, 72, 87) 100%
  );
  background-size: 200% 100%;
  background-position: right;

  border-radius: 6px;

  transition: background 0.5s ease;

  &:hover {
    color: rgba($color: #FFFFFF, $alpha: 1.0);
    background-position: left;
    box-shadow: 0px 1px 4px 2px rgba($color: #a4adbb, $alpha: 0.45);
    transition: all .35s ease;
  }
}

.btn__link {
  background-image: none;
  background: linear-gradient(
    90deg,
    rgb(239, 50, 66) 0%,
    rgb(239, 50, 66) 50%,
    rgb(239, 72, 87) 50%,
    rgb(239, 72, 87) 100%
  );
  background-size: 200% 100%;
  background-position: right;

  transition: background 0.5s ease;
}

.btn__link:hover {
  color: rgba($color: #FFFFFF, $alpha: 1.0);
  background-position: left;
  box-shadow: 0px 0px 6px 2px rgba($color: #68778d, $alpha: 0.5);
  transition: all .35s ease;
}
.btn-segundo {
  background-image: none;
  background: linear-gradient(
    90deg,
    rgb(101, 36, 124) 0%,
    rgb(101, 36, 124) 50%,
    rgb(101, 58, 145) 50%,
    rgb(101, 58, 145) 100%
  );
  background-size: 200% 100%;
  background-position: right;
  transition: background 0.5s ease;
}

.btn-segundo:hover {
  background-position: left;
}

@media (min-width: 1200px) {
  .main-bolsa {
    width: 100%;
    height: auto;
  }

  .title_page {
    margin: -8px 0px 0px !important;
    padding: 55px 0px 50px;
    background-repeat: repeat;
    background-position: center;
    background-color: rgb(239, 72, 87);
    background-image: url("https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/cpv-fundo-4.png");
    background-size: 30%;
    // background-attachment: fixed;
    object-fit: cover;
    border-radius: 0;

    h1 {
      font-size: 51px;
      font-weight: 700;
      color: #ffffff;
      text-transform: uppercase;
      font-family: "FF Clan Pro", sans-serif;
    }
  }

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(58, 28, 83);
  }

  .header h4 {
    font-size: 26px;
    font-weight: bold;
    line-height: 1.385;
    letter-spacing: 0.81px;
    text-transform: uppercase;
    text-align: center;
  }

  .header h4 .emphasis {
    color: rgb(239, 72, 87);
  }

  .content-bolsa {
    width: 100%;
    display: flex;
    align-items: left;
    justify-content: left;
    flex-direction: column;
    color: #333333;
  }

  .content-bolsa h6, h5 {
    font-size: 20px;
    color: rgb(58, 28, 83);
  }

  .content-bolsa h6 .emphasis, .content-bolsa h5 .emphasis {
    color: rgb(239, 72, 87);
  }

  .content-bolsa span {
    font-weight: bold;
  }

  .content-bolsa .info-prova {
    width: 100%;
    height: auto;
    display: flex;
    align-items: left;
    justify-content: left;
    flex-direction: column;
  }

  .content-bolsa .info-prova h4 {
    font-size: 22px;
    font-weight: bold;
  }

  .content-bolsa .info-prova ul {
    margin-left: 2%;
  }
  .content-bolsa .info-prova ul li span {
    font-weight: bold;
  }
  .content-bolsa .info-prova ul li {
    padding: 5px 0px;
  }
  .content-bolsa .info-prova ul li a {
    font-weight: bold;
    color: #333333;
    text-decoration: none;
  }
  .content-bolsa .info-prova ul li a:hover {
    font-weight: bold;
    color: rgb(239, 72, 87);
    text-decoration: none;
    transition: all 0.3s ease;
  }

  .button-inscricao .btn_insc {
    height: 56px;
    width: 35% !important;
  }

  .button-inscricao .btn_inscricao {
    border: 2px solid rgb(239, 72, 87);
    border-radius: 5px;
    background-color: rgb(239, 72, 87);
    // box-shadow: 0px 0px 30px rgba(1, 41, 141, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }

  .button-inscricao .btn_inscricao span {
    color: #ffffff;
    text-transform: uppercase;
  }

  .content-bolsa-font {
    font-family: "Clan-Book Book", sans-serif;
    font-size: 16px;
  }
}

@media only screen 
  and (min-width: 768px) 
  and (max-width: 1200px) {
  .main-bolsa {
    width: 100%;
    height: auto;
    font-size: 16px;
    margin: 80px 0;
  }

  .title_page {
    margin: -20px 0px -10px;
    padding: 55px 0px 50px;
    background-repeat: repeat;
    background-position: center;
    background-color: rgb(259, 72, 87);
    background-image: url("https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/cpv-fundo-4.png");
    border-radius: 0;
  }

  .title_page h1 {
    font-size: 41px;
    font-weight: 700;
    color: #ffffff;
    text-transform: uppercase;
    font-family: "FF Clan Pro", sans-serif;
  }

  .adjustment-breadcrumb {
    margin: 10px 0 50px !important;
  }

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .header h4 {
    font-size: 26px;
    font-weight: bold;
    line-height: 1.385;
    letter-spacing: 0.81px;
    text-transform: uppercase;
    text-align: center;
    color: rgb(58, 28, 83);
  }

  .header h4 .emphasis {
    color: rgb(239, 72, 87);
  }

  .content-bolsa {
    width: 100%;
    display: flex;
    align-items: left;
    justify-content: left;
    flex-direction: column;
    color: #333333;
  }

  .content-bolsa h6, h5 {
    font-size: 20px;
    color: rgb(58, 28, 83);
  }

  .content-bolsa h6 .emphasis, .content-bolsa h5 .emphasis {
    color: rgb(239, 72, 87);
  }

  .content-bolsa span {
    font-weight: bold;
  }

  .content-bolsa .info-prova {
    width: 100%;
    height: auto;
    display: flex;
    align-items: left;
    justify-content: left;
    flex-direction: column;
  }

  .content-bolsa .info-prova h4 {
    font-size: 22px;
    font-weight: bold;
  }

  .content-bolsa .info-prova ul {
    margin-left: 2%;
  }
  .content-bolsa .info-prova ul li span {
    font-weight: bold;
  }
  .content-bolsa .info-prova ul li {
    padding: 5px 0px;
  }
  .content-bolsa .info-prova ul li a {
    font-weight: bold;
    color: #333333;
    text-decoration: none;
  }
  .content-bolsa .info-prova ul li a:hover {
    font-weight: bold;
    color: rgb(239, 72, 87);
    text-decoration: none;
    transition: all 0.3s ease;
  }

  .button-inscricao .btn_insc {
    height: 56px;
    width: 35% !important;
  }

  .button-inscricao .btn_inscricao {
    border: 2px solid rgb(239, 72, 87);
    border-radius: 5px;
    background-color: rgb(239, 72, 87);
    // box-shadow: 0px 0px 30px rgba(1, 41, 141, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }

  .button-inscricao .btn_inscricao span {
    color: #ffffff;
    text-transform: uppercase;
  }

  .content-bolsa-font {
    font-family: "Clan-Book Book", sans-serif;
    font-size: 16px;
  }
}

@media only screen 
  and (max-width: 768px) {
    .main-bolsa {
      width: 100%;
      height: auto;
      font-family: "Clan-Book Book", sans-serif;
      font-size: 16px;
    }

    .title_page {
      margin: -10px 0px -10px;
      padding: 55px 0px 50px;
      background-repeat: repeat;
      background-position: center;
      background-color: rgb(259, 72, 87);
      background-image: url("https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/cpv-fundo-4.png");
      border-radius: 0;
    }

    .title_page h1 {
      font-size: 41px;
      font-weight: 700;
      color: #ffffff;
      text-transform: uppercase;
    }

    .adjustment-breadcrumb {
      margin-top: 0px !important;
    }

    .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .header h4 {
    font-size: 26px;
    font-weight: bold;
    line-height: 1.385;
    letter-spacing: 0.81px;
    text-transform: uppercase;
    text-align: center;
    color: rgb(58, 28, 83);
  }

  .header h4 .emphasis {
    color: rgb(239, 72, 87);
  }

  .content-bolsa {
    width: 100%;
    display: flex;
    align-items: left;
    justify-content: left;
    flex-direction: column;
    color: #333333;
  }

  .content-bolsa h6, h5 {
    font-size: 20px;
    color: rgb(58, 28, 83);
  }

  .content-bolsa h6 .emphasis, .content-bolsa h5 .emphasis {
    color: rgb(239, 72, 87);
  }

  .content-bolsa span {
    font-weight: bold;
  }

  .content-bolsa .info-prova {
    width: 100%;
    height: auto;
    display: flex;
    align-items: left;
    justify-content: left;
    flex-direction: column;
  }

  .content-bolsa .info-prova h4 {
    font-size: 22px;
    font-weight: bold;
  }

  .content-bolsa .info-prova ul {
    margin-left: 2%;
  }
  .content-bolsa .info-prova ul li span {
    font-weight: bold;
  }
  .content-bolsa .info-prova ul li {
    padding: 5px 0px;
  }
  .content-bolsa .info-prova ul li a {
    font-weight: bold;
    color: #333333;
    text-decoration: none;
  }
  .content-bolsa .info-prova ul li a:hover {
    font-weight: bold;
    color: rgb(239, 72, 87);
    text-decoration: none;
    transition: all 0.3s ease;
  }

  .button-inscricao .btn_insc {
    height: 56px;
    width: 35% !important;
  }

  .button-inscricao .btn_inscricao {
    border: 2px solid rgb(239, 72, 87);
    border-radius: 5px;
    background-color: rgb(239, 72, 87);
    // box-shadow: 0px 0px 30px rgba(1, 41, 141, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }

  .button-inscricao .btn_inscricao span {
    color: #ffffff;
    text-transform: uppercase;
  }

  .content-bolsa-font {
    font-family: "Clan-Book Book", sans-serif;
    font-size: 16px;
  }
}
</style>
