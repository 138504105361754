import Vue from "vue";
import Router from "vue-router";
import routes from "./routes";
import store from "../store";
import auth from "./auth";
import user from "./user";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [...routes, ...auth, ...user],
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  // console.log(to.path, JSON.stringify(to.params), JSON.stringify(to.query));
  const dominioAtual = window.location.origin;
  if (
    dominioAtual === to.fullPath.split(to.path)[0] + window.location.host &&
    to.path === from.path &&
    JSON.stringify(to.params) === JSON.stringify(from.params) &&
    JSON.stringify(to.query) === JSON.stringify(from.query)
  ) {
    // Evita a navegação se for para a mesma rota
    console.log("To: ", to.path, JSON.stringify(to.params), JSON.stringify(to.query));
    console.log("From: ", from.path, JSON.stringify(from.params), JSON.stringify(from.query));
    // next();
    next(false); // Cancela a navegação
  } else {
    const testandoNovo = $cookies.get("testando_novo");
    let privatePages = ["user-dashboard", "user-matricula"];
    if (testandoNovo) {
      privatePages = ["matricula", "user-dashboard", "user-matricula"];
    }

    const authRequired = privatePages.includes(to.name);

    if (authRequired) {
      const authData = JSON.parse(localStorage.getItem("auth"));

      if (authData && authData.token && authData.userType == "siteuser") {
        // Usuário do site logado
        next(true);
      } else {
        $cookies.set("sitecpv_destino", {
          name: to.name,
          params: to.params,
        });
        // Usuário do site não logado
        next({ path: "/login" });
      }
    }

    // scroll to top when changing pages
    if (document.scrollingElement) {
      document.scrollingElement.scrollTop = 0;
    } else if (document.documentElement) {
      document.documentElement.scrollTop = 0;
    }

    store.commit("setLayout", to.meta.layout_name);

    next();
  }
});

export default router;
