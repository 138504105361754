<template>
  <div>
    <b-jumbotron class="title_courses">
      <h1>{{ title }}</h1>
    </b-jumbotron>
  </div>
</template>

<script>
export default {
  props: ["title"],
};
</script>

<style scoped>
.title_courses {
  margin: -8px 0px 0px !important;
  background-repeat: repeat;
  background-position: center;
  background-color: rgb(239, 72, 87);
  background-image: url("https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/cpv-fundo-4.png");
  background-size: 30%;
  object-fit: cover;
  border-radius: 0;
}

.title_courses h1 {
  text-align: center;
  text-transform: uppercase;

  font-family: "FF Clan Pro", sans-serif;
  font-size: 3.188rem;
  font-weight: 700;

  color: #ffffff;
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .title_courses {
    padding: 3.438rem 0;
  }

  .title_courses h1 {
    text-align: center;
    text-transform: uppercase;

    font-size: 3.188rem;
    font-weight: 800;
  }
}
@media (min-width: 577px) and (max-width: 767px) {
  .title_courses {
    padding: 3.125rem 0.625rem;
  }

  .title_courses h1 {
    text-align: center;
    text-transform: uppercase;

    font-size: 2.563rem;
    font-weight: 800;
  }
}
@media (min-width: 320px) and (max-width: 576px) {
  .title_courses {
    padding: 3.125rem 0.625rem;
  }

  .title_courses h1 {
    text-align: center;
    text-transform: uppercase;

    font-size: 2.563rem;
    font-weight: 800;
  }
}
</style>
