const routes = [
  {
    path: "/user/dashboard",
    name: "user-dashboard",
    component: () => import(/* webpackChunkName: "user-dashboard" */ "../views/user/dashboard.vue"),
    meta: { layout_name: "user", section_childname: "dashboard", title: "Minhas Matrículas" },
  },
  {
    path: "/user/matricula/:enrollmentId",
    name: "user-matricula",
    component: () => import(/* webpackChunkName: "user-matricula" */ "../views/user/user-matricula.vue"),
    props: (route) => {
      return {
        ...route.params,
        ...{ enrollmentId: Number.parseInt(route.params.enrollmentId, 10) },
      };
    },
    meta: { layout_name: "user", section_childname: "user-matricula", title: "Matrícula" },
  },
];
export default routes;
